(function($, DIC){
	const a = 'is-active';
	const v = 'is-visible';
	const f = 'is-fixed';
	const slideSpeed = '400';
	const lang = location.pathname.split('/')[1];
	let addHtml, itemList, mpoketLang, margeList;
	if(lang === 'ja') {
		mpoketLang = 'all';
	} else {
		mpoketLang = 'enall';
	}

	// ＜共通＞JSON取得 ---------------------------------------------------
	// トップページニュース・ニュースティッカー部分
	const getJSONcmsTop = () => {
		return $.getJSON(`/${lang}/ajax/index.json`);
	};
	// ニューストップ・ニュースIR部分
	const getJSONcmsNews = (_year) => {
		return $.getJSON(`/${lang}/ajax/news/${_year}/index.json`);
	};
	// irトップ（お知らせ／適時開示情報・最新決算情報）部分
	const getJSONcmsIr = () => {
		return $.getJSON(`/${lang}_ir/ajax/index.json`);
	};
	// マジカルポケット、すべてのニュース
	const getJSONmpocket = () => {
		return $.ajax({
			type: 'GET',
			url: `https://xml.irpocket.com/4631/JS/release-${mpoketLang}-all.js`,
			dataType: 'jsonp',
			jsonp: 'callback',
			jsonpCallback: 'ir20handler'
		});
	};
	// マジカルポケット、年別取得
	const getJSONmpocketNews = (_year) => {
		return $.ajax({
			type: 'GET',
			url: `https://xml.irpocket.com/4631/JS/release-${mpoketLang}-12m-${_year}01.js`,
			dataType: 'jsonp',
			jsonp: 'callback',
			jsonpCallback: 'ir20handler'
		});
	};
	// ＜共通＞各jsonを整型→マージ---------------------------------------------------
	const cmsDataMolding = (_cms) => {
		let cmsData = _cms.rt_bn_news_list;
		// cmsニュースの配列作成
		let cmsDataList = [];
		for(let i = 0; i < cmsData.length; i++) {
			// リンクタイプだし分け
			let cmshref = '';
			let cmstarget = '';
			let cmslinkNoneClass = '';
			if(cmsData[i].cf_n_type === 1 || cmsData[i].cf_n_type === 4 || cmsData[i].cf_n_type === 6 || cmsData[i].cf_n_type === 7) {
				cmshref = cmsData[i].rt_cf_n_href.href;
			} else if(cmsData[i].cf_n_type === 2 || cmsData[i].cf_n_type === 3) {
				cmshref = cmsData[i].rt_cf_n_href.href;
				cmstarget = '_blank';
			} else if(cmsData[i].cf_n_type === 5) {
				cmshref = '';
				cmslinkNoneClass= 'mod-news-item-linkNone';
			}
			// ニュースリリースフラグだし分け
			let cmsflag = '';
			if(cmsData[i].rt_cf_n_newsrelease_flag) {
				if(lang === 'ja') {
					cmsflag = '<span class="mod-news-item-label">ニュースリリース</span>';
				} else {
					cmsflag = '<span class="mod-news-item-label">News Release</span>';
				}
			}
			// 画像だし分け(画像がないものには、それぞれのカテゴリの画像を割り当て)
			let cmsimg = '';
			if(cmsData[i].rt_cf_n_thum_img) {
				cmsimg = cmsData[i].rt_cf_n_thum_img.src;
			} else {
				if(cmsData[i].cf_n_category.cf_nc_code === 'products') {
					cmsimg = '/images/news/products.jpg';
				} else if(cmsData[i].cf_n_category.cf_nc_code === 'ir') {
					cmsimg = '/images/news/ir.jpg';
				} else if(cmsData[i].cf_n_category.cf_nc_code === 'csr') {
					cmsimg = '/images/news/csr.jpg';
				} else if(cmsData[i].cf_n_category.cf_nc_code === 'event') {
					cmsimg = '/images/news/event.jpg';
				} else if(cmsData[i].cf_n_category.cf_nc_code === 'other') {
					cmsimg = '/images/news/other.jpg';
				} else if(cmsData[i].cf_n_category.cf_nc_code === 'r_and_d') {
					cmsimg = '/images/news/r_and_d.jpg';
				}
			}
			// 国別カテゴリー名だし分け
			let cmsCategoryName = '';
			if(lang === 'ja') {
				cmsCategoryName = cmsData[i].cf_n_category.cf_nc_name_ja;
			} else {
				cmsCategoryName = cmsData[i].cf_n_category.cf_nc_name_en;
			}
			// 年・日時を取得(JSTに変換)
			let cmsdate = new Date(cmsData[i].cf_n_date);
			cmsDataList[i] = {
				categoryCord: cmsData[i].cf_n_category.cf_nc_code,
				date: cmsdate,
				title: cmsData[i].cf_n_title,
				categoryName: cmsCategoryName,
				flag: cmsflag,
				flag2: '',
				img: cmsimg,
				href: cmshref,
				target: cmstarget,
				linkNoneClass: cmslinkNoneClass
			};
		}
		return cmsDataList;
	};
	const mpocketDataMolding = (_mpocket) => {
		let mpocketData = _mpocket.item;
		// console.log(mpocketData, 'mpocketData');
		// mpocketニュースの配列作成
		let mpocketDataList = [];
		for(let k = 0; k < mpocketData.length; k++) {
			// 国別カテゴリー名だし分け
			let mpocketCategoryName = '';
			if(lang === 'ja') {
				mpocketCategoryName = '経営・IR';
			} else {
				mpocketCategoryName = 'Management & IR';
			}
			// 年・日時を取得
			let mpocketdate = new Date(mpocketData[k].published.replace(/\-/g, '/'));
			// console.log(mpocketdate, 'mpocketdate');
			// フラグだし分け
			let mpocketflag1 = '';
			let mpocketflag2 = '';
			if((lang === 'ja') && !(mpocketData[k].category_name === '有価証券報告書')) {
				mpocketflag1 = '<span class="mod-news-item-label mod-news-item-label-timelyDisclosure">適時開示</span>';
				mpocketflag2 = '<span class="mod-newsList-label-min">適時開示</span>';
			} else {
				mpocketflag1 = '<span class="mod-news-item-label mod-news-item-label-timelyDisclosure">TSE Filings</span>';
				mpocketflag2 = '<span class="mod-newsList-label-min">TSE Filings</span>';
			}

			mpocketDataList[k] = {
				categoryCord: 'ir',
				date: mpocketdate,
				title: mpocketData[k].title,
				categoryName: mpocketCategoryName,
				flag: mpocketflag1,
				flag2: mpocketflag2,
				img: '/images/news/ir_tse.jpg',
				href: mpocketData[k].link,
				target: '_blank',
				linkNoneClass: ''
			};
		}
		return mpocketDataList;
	};
	const itemListMarge = ($cms, $mpoket) => {
		// 配列をマージ
		itemList = cmsDataMolding($cms).concat(mpocketDataMolding($mpoket));
		// 配列を日付順でソート
		itemList.sort(function($itemlista, $itemlistb){
			return($itemlista.date < $itemlistb.date ? 1 : -1);
		});
		return itemList;
	};
	const itemListDateChange = ($list) => {
		itemList = $list;
		// 日付を日英別で変換
		let itemListdate, itemListyear, itemListmonth, itemListmonthEn, itemListday;
		for(let j = 0; j < itemList.length; j++) {
			itemListdate = new Date(itemList[j].date);
			// console.log(itemList[j],itemListdate,'itemListdate');
			itemListyear = itemListdate.getFullYear();
			itemListmonth = itemListdate.getMonth() + 1;
			itemListday = itemListdate.getDate();
			if(lang === 'ja') {
				itemList[j].date = itemListyear+'年'+itemListmonth+'月'+itemListday+'日';
			} else {
				switch(itemListmonth) {
				case 1 : itemListmonthEn = 'Jan.'; break;
				case 2 : itemListmonthEn = 'Feb.'; break;
				case 3 : itemListmonthEn = 'Mar.'; break;
				case 4 : itemListmonthEn = 'Apr.'; break;
				case 5 : itemListmonthEn = 'May'; break;
				case 6 : itemListmonthEn = 'Jun.'; break;
				case 7 : itemListmonthEn = 'Jul.'; break;
				case 8 : itemListmonthEn = 'Aug.'; break;
				case 9 : itemListmonthEn = 'Sep.'; break;
				case 10 : itemListmonthEn = 'Oct.'; break;
				case 11 : itemListmonthEn = 'Nov.'; break;
				case 12 : itemListmonthEn = 'Dec.'; break;
				default: itemListmonthEn = 1;
				}
				itemList[j].date = itemListmonthEn+' '+itemListday+', '+itemListyear;
			}
			if($('.js-ir-news-listLink').length) {
				if(j == '0') {
					$('.js-ir-news-listLink').attr('href', '/'+lang+'/news/'+itemListyear+'/ir/');
				}
			}
		}
		return itemList;
	};
	// ＜共通＞書き出し用html------------------------------------------------
	const boxListHtml = ($itemList, $maxNum, $addArea) => {
		for(let l = 0; l < $maxNum; l++) {
			addHtml = '<li>' +
			'<a href="'+ $itemList[l].href +'" class="mod-news-item '+ $itemList[l].linkNoneClass +'" target="'+ $itemList[l].target +'">' +
			'<figure class="mod-news-item-img">' +
			'<img src="'+ $itemList[l].img +'" class="width-u-100" alt="'+ $itemList[l].title +'">'+
			'<figcaption class="mod-label ' + $itemList[l].categoryCord + '">' + $itemList[l].categoryName + '</figcaption>' +
			'</figure>' +
			'<div class="mod-news-item-text">' +
			'<span class="mod-news-item-head">' +
			'<span class="mod-news-item-day"><i class="icon icon-time"></i><span class="">'+ $itemList[l].date +'</span></span>' +
			'<span class="mod-label ' + $itemList[l].categoryCord + '">' + $itemList[l].categoryName + '</span>' +
			$itemList[l].flag +	'</span><span class="mod-news-item-title">'+ $itemList[l].title +'</span></div></a></li>';
			$(addHtml).appendTo($addArea);
		}
	};
	const tickerHtml = ($itemList, $maxNum, $addArea) => {
		for(let l = 0; l < $maxNum; l++) {
			addHtml = '<li class="index-ticker-item">' +
			'<a href="'+$itemList[l].href+'" class="mod-a" target="'+$itemList[l].target+'">' +
			'<p class="index-ticker-title">' +
			'<span class="index-ticker-title-text">'+$itemList[l].title+'</span>' +
			'</p></a></li>';
			$(addHtml).appendTo($addArea);
		}
	};
	const textListHtml = ($itemList, $maxNum, $addArea) => {
		for(let l = 0; l < $maxNum; l++) {
			addHtml = '<li class="mod-newsList">' +
			'<span class="mod-newsList-day">' +
			'<span><i class="icon icon-time"></i><span>'+$itemList[l].date+'</span></span></span>' +
			'<span class="mod-newsList-label"><span class="mod-label '+$itemList[l].categoryCord+'">'+$itemList[l].categoryName+'</span></span>' +
			'<span class="mod-newsList-title"><a href="'+$itemList[l].href+'" class="'+$itemList[l].linkNoneClass+'" target="'+$itemList[l].target+'">' +
			$itemList[l].title+$itemList[l].flag2+'</a></span></li>';
			$(addHtml).appendTo($addArea);
		}
	};
	const textListHtmlTop = ($itemList, $maxNum, $addArea) => {
		for(let l = 0; l < $maxNum; l++) {
			addHtml = '<li>' +
			'<a href="'+ $itemList[l].href +'" class="mod-news-item '+ $itemList[l].linkNoneClass +'" target="'+ $itemList[l].target +'">' +
			'<div class="mod-news-item-text">' +
			'<span class="mod-news-item-head">' +
			'<span class="mod-news-item-day"><i class="icon icon-time"></i><span class="">'+ $itemList[l].date +'</span></span>' +
			'<span class="_labelWrap"><span class="mod-label ' + $itemList[l].categoryCord + '">' + $itemList[l].categoryName + '</span>' +
			$itemList[l].flag +	'</span></span><span class="mod-news-item-title">'+ $itemList[l].title +'</span></div></a></li>';
			$(addHtml).appendTo($addArea);
		}
	};
	// <共通>---------------------------------------------------------------
	// トップページニュース・ニュースティッカー部分
	const setJSONTop = () => {
		if($('.js-top-ticker').length) {
			// ティッカー用
			const setTicker = () => {
				// const cutTickerText = (_cnt) => {
				// 	$('.index-ticker-item').find('.index-ticker-title-text').each((idx, ele) => {
				// 		var rawText = $(ele).text();
				// 		if(rawText.length > _cnt) {
				// 			$(ele).text(rawText.substring(0, _cnt) + '...');
				// 		}
				// 	});
				// };
				// if($(window).width() >= 1024) {
				// 	cutTickerText(40);
				// } else if($(window).width() < 1024 && $(window).width() >= 768) {
				// 	cutTickerText(30);
				// } else if($(window).width() < 768) {
				// 	cutTickerText(5);
				// }
				const $setElm = $('.js-ticker ul');
				$setElm.slick({
					autoplay: true,
					slidesToShow: 1,
					slidesToScroll: 1,
					autoplaySpeed: 5000,
					loop: true,
					speed: 1000,
					arrows: false,
					dots: false
				});
				setTimeout(function(){
					$('.js-ticker').addClass('is-active');
				}, 100);
			};
			getJSONcmsTop().done((cms) => {
				getJSONmpocket().done((mpocket) => {
					// リストをマージ
					margeList = itemListMarge(cms, mpocket);
					// html出力
					let datachangeList = itemListDateChange(margeList);
					if($('.js-top-newsList').length) {
						// textListHtmlTop(itemListDateChange(cmsDataMolding(cms)), 3, $('.js-top-newsList'));
						// textListHtmlTop(itemListDateChange(mpocketDataMolding(mpocket)), 2, $('.js-top-newsList'));
						textListHtmlTop(datachangeList, 5, $('.js-top-newsList'));
					}
					tickerHtml(datachangeList, 8, $('.js-top-ticker'));
					setTimeout(function(){
						setTicker();
					}, 500);
					if($('.js-top-newsList-ir').length) {
						getJSONcmsIr().done((cmsir) => {
							// リストをマージ
							let margeListir = itemListMarge(cmsir, mpocket);
							// console.log(margeListir,'margeListir');
							// html出力
							// textListHtmlTop(itemListDateChange(cmsDataMolding(cmsir)), 3, $('.js-top-newsList-ir'));
							// textListHtmlTop(itemListDateChange(mpocketDataMolding(mpocket)), 2, $('.js-top-newsList-ir'));
							textListHtmlTop(itemListDateChange(margeListir), 5, $('.js-top-newsList-ir'));
						});
					}
				});
			});
		}
	};

	// ニューストップ・ニュースIR部分
	const setJSONNews = () => {
		if($('.js-news-list').length) {
			if(location.pathname.split('/')[4] === 'ir' || location.pathname.split('/')[4] === '') {
				$('.js-news-list').empty();
				let yearCategory = location.pathname.split('/')[3];
				if(location.pathname.split('/')[4] === 'ir') {
					yearCategory = location.pathname.split('/')[3]+'/ir';
				}
				if(location.pathname.split('/')[3] > 2018) {
					getJSONcmsNews(yearCategory).done((cms) => {
						getJSONmpocketNews(location.pathname.split('/')[3]).done((mpocket) => {
							// リストをマージ
							margeList = itemListMarge(cms, mpocket);
							if(!margeList.length) {
								if(lang === 'ja') {
									let adderrorHtml = '<li>こちらのニュースはございません</li>';
									$(adderrorHtml).appendTo($('.js-news-list'));
								} else {
									let adderrorHtml = '<li>There is no news here.</li>';
									$(adderrorHtml).appendTo($('.js-news-list'));
								}
							} else {
								// html出力
								boxListHtml(itemListDateChange(margeList), margeList.length, $('.js-news-list'));
							}
						});
					});
				} else {
					getJSONcmsNews(yearCategory).done((cms) => {
						// html出力
						textListHtml(itemListDateChange(cmsDataMolding(cms)), itemListDateChange(cmsDataMolding(cms)).length, $('.js-news-list'));
					});
				}
				setTimeout(function(){
					$('.js-news-list').addClass(a);
				}, 100);
			} else {
				// 通常のニュースリスト表示
				$('.js-news-list').addClass(a);
				if(!$('.js-news-list li').length) {
					if(lang === 'ja') {
						let adderrorHtml = '<li>こちらのニュースはございません。</li>';
						$(adderrorHtml).appendTo($('.js-news-list'));
					} else {
						let adderrorHtml = '<li>There is no news here.</li>';
						$(adderrorHtml).appendTo($('.js-news-list'));
					}
				}
			}
		}
	};

	// irトップ（お知らせ／適時開示情報・最新決算情報）部分
	const setJSONIr = () => {
		if($('.js-ir-news').length) {
			getJSONcmsIr().done((cms) => {
				getJSONmpocket().done((mpocket) => {
					// リストをマージ
					margeList = itemListMarge(cms, mpocket);
					// html出力
					textListHtml(itemListDateChange(margeList), 5, $('.js-ir-news'));
				});
			});
		}
	};
	setJSONTop();
	setJSONNews();
	setJSONIr();

})(window.jQuery, window.DIC);
